import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const OurHistory = () => {

  const data = useStaticQuery(graphql`
        query HistoryQuery {
            allHistoryJson(sort: {order: DESC, fields: date}) {
                nodes {
                    date(formatString: "DD.MM.YYYY")
                    year:date(formatString: "YYYY")
                    header
                    content
                    featuredimage {
                        childImageSharp {
                            gatsbyImageData
                        }
                   }     
                }
            }
        }
    `)
  return (
    <section className="history-area ptb-100 bg-fafafb">
      <div className="container">
        <div className="section-title">
          <h2>Unsere Geschichte beginnt in 2003</h2>
        </div>

        <ol className="timeline history-timeline">

          {data.allHistoryJson.nodes.map((history) => (
            <li className="timeline-block">
              <div className="timeline-date">
                <span className={"h4"}>{history.year}</span>
              </div>

              <div className="timeline-icon">
                <span className="dot-badge" />
              </div>

              <div className="timeline-content">
                <div className="row align-items-center">
                  <div className="col-lg-7 col-md-12">
                    <div className="content">
                      <h3>{history.header}</h3>
                      <p>{history.content}</p>
                    </div>
                  </div>

                  <div className="col-lg-5 col-md-12">
                    <div className="image">
                      <GatsbyImage image={getImage(history.featuredimage)}
                                   alt={history.header} />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </section>
  )
}

export default OurHistory

import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import OurHistory from '../components/AboutUs/OurHistory'
import Seo from "../components/App/Seo"

const AboutUs = () => {
  return (
    <Layout>
      <Seo title={"Unsere Firmenhistorie für Sie visualisiert"}
           description={"LOGENTIS ist seit über 17 Jahren im Bereich Intralogistik aktiv. " +
           "Wir haben für Sie die wichtigsten Milestones der letzten zwei Jahrzehnte " +
           "zusammengefasst."}
           image={"/images/og/logentis-main-og.png"}
      />
      <Navbar />
      <PageBanner
        bannerClassname={"page-title-area"}
        pageTitle="Firmenhistorie"
        homePageText="Über uns"
        homePageUrl="/ueber-uns/"
        activePageText="Firmenhistorie"
        breadcrumbs={[["Home", "/"], ["Über uns", "/ueber-uns/"]]}
      />
      <OurHistory />
      <Footer />
    </Layout>
  );
}

export default AboutUs;
